import React from "react";
import { View, ScrollView, Text, Image, ImageBackground, TouchableOpacity, SafeAreaView, Linking } from "react-native";
import { useNavigation } from "@react-navigation/native";
import MobileHeader from "../components/mHeader";
import Footer from "../components/footer";
import Header from "../components/header";
import i18n from "../components/i18n";
import CookieConsent from "../components/CookieConsent";
import NewMobileHeader from "../components/newMobileHeader";

const Outlets = () => {
  const navigation = useNavigation();

  return (
    <View className="md:bg-[#efefef]">
      <ImageBackground source={require("../assets/login-bg.png")} resizeMode="cover" className="flex-1 justify-start items-center w-full h-full">
        <SafeAreaView className="bg-white flex justify-start items-center w-full md:bg-transparent">
          <Header />
          <NewMobileHeader />
          <MobileHeader title={i18n.t("outlets.outlets")} />
          <View className="w-11/12 md:w-4/5 md:max-w-screen-xl md:h-auto md:m-auto md:bg-white md:p-7 mt-6 md:mb-24 pb-10">
            <Text className="text-black text-2xl mt-6 mb-4 uppercase font-Crimson md:text-center">{i18n.t("outlets.outlets")}</Text>
            <View className="w-full h-[0.5px] bg-brandgold mt-3"></View>

            <View className="mt-6">
              <View className="hidden md:flex flex-row bg-[#D9D9D9]">
                <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.restaurant_name")}</Text>
                <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.address")}</Text>
                <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.contact")}</Text>
                <Text className="w-[25%] px-5 py-3 text-base font-semibold font-Crimson">{i18n.t("outlets.email")}</Text>
              </View>
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_1_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_1_2")} <br />
                  {i18n.t("outlets.outlets_1_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_1_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL(`mailto:mbs@imperialtreasure.com`);
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_1_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_2_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_2_2")} <br />
                  {i18n.t("outlets.outlets_2_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_2_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:paragon@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_2_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_3_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_3_2")}
                  <br />
                  {i18n.t("outlets.outlets_3_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_3_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:asq@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_3_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_15_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_15_2")}
                  <br />
                  {i18n.t("outlets.outlets_15_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_15_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:spd_jewel@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_15_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_4_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_4_2")}
                  <br />
                  {i18n.t("outlets.outlets_4_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_4_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:fineteochew_ion@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_4_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_5_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_5_2")}
                  <br />
                  {i18n.t("outlets.outlets_5_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_5_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:fineteochew_mbs@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_5_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_6_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_6_2")}
                  <br />
                  {i18n.t("outlets.outlets_6_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_6_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:sh_nac4@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_6_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_7_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_7_2")}
                  <br />
                  {i18n.t("outlets.outlets_7_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_7_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:cc_gwc@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_7_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_8_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_8_3")}
                  <br />
                  {i18n.t("outlets.outlets_8_4")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_8_6")}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:bar_grill_gw@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_8_7")}</Text>
                </TouchableOpacity>
              </View>
              {/* <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_9_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_9_2")}
                  <br />
                  {i18n.t("outlets.outlets_9_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_9_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:ion_steamboat@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_9_5")}</Text>
                </TouchableOpacity>
              </View> */}
              {/* <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_10_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_10_2")}
                  <br />
                  {i18n.t("outlets.outlets_10_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_10_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:steamboat_gwc@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_10_5")}</Text>
                </TouchableOpacity>
              </View> */}
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_11_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_11_2")}
                  <br />
                  {i18n.t("outlets.outlets_11_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_11_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:steamboat_111@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_11_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_12_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_12_2")}
                  <br />
                  {i18n.t("outlets.outlets_12_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_12_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:paragon_ydx@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_12_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              {/* <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_13_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_13_2")}
                  <br />
                  {i18n.t("outlets.outlets_13_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_13_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:parkway_ydx@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_13_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" /> */}
              <View className="flex flex-col md:flex-row bg-white md:bg-[#F6F6F6]">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_14_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_14_2")}
                  <br />
                  {i18n.t("outlets.outlets_14_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_14_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:rafflescity_ydx@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_14_5")}</Text>
                </TouchableOpacity>
              </View>
              <View className="border-b-2 border-b-gray-200 my-3 block md:hidden" />
              <View className="flex flex-col md:flex-row bg-white">
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson font-semibold md:font-normal">{i18n.t("outlets.outlets_16_1")}</Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">
                  {i18n.t("outlets.outlets_16_2")}
                  <br />
                  {i18n.t("outlets.outlets_16_3")}
                </Text>
                <Text className="w-full md:w-[25%] p-0 md:p-5 text-base font-Crimson">{i18n.t("outlets.outlets_16_4")}</Text>
                <TouchableOpacity
                  onPress={() => {
                    Linking.openURL("mailto:spd_jewel@imperialtreasure.com");
                  }}
                  className="w-full md:w-[25%] p-0 md:p-5"
                >
                  <Text className="text-base underline font-Crimson">{i18n.t("outlets.outlets_16_5")}</Text>
                </TouchableOpacity>
              </View>              
            </View>
          </View>

          <Footer />
          <CookieConsent />
        </SafeAreaView>
      </ImageBackground>
    </View>
  );
};

export default Outlets;
